<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div slot="title">
      {{ getTitleByIndex(tabIndex) }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      >
        <template slot="kpi">
          <el-input
            :value="formModel.kpi ? formModel.kpi.name : ''"
            @focus="isShowKpiSelector('kpi')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            />
            <i
              v-show="formModel.kpi"
              slot="suffix"
              class="el-icon-circle-close"
              @click="formModel.kpi={}"
            />
          </el-input>
        </template>
        <!-- <template slot="compareKpi">
          <el-input
            :disabled="isSpecialCheckRule"
            :value="formModel.compareKpi ? formModel.compareKpi.name : ''"
            @focus="isShowKpiSelector('compareKpi')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            />
          </el-input>
        </template> -->
      </simple-form>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
    <selected-table
      :show-flag.sync="isShowKpiPicker"
      :title="$t('config_kpi.kpi')"
      url="/jlr/mt/kpi/list"
      :table-column="kpiTableColumn"
      :search-form-field="kpiSearchFormField"
      :selected-data.sync="selectedData"
      @confirm="confirmHandler"
    />
  </el-dialog>
</template>
<script>
const BASEURL = {
  save1: '/mt/groupItem/batchEditChangeRate',
  save2: '/mt/groupItem/batchEditThresholdAbsolute'
}

export default {
  name: 'KpiAdd',
  props: {
    tabIndex: { type: Number, required: true },
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean, default: false }
    // detailData: { type: Object },
    // rowIndex: { type: Number }
    // rowDetail: { type: Object, required: true }
  },
  data () {
    return {
      currentIndex: null,
      formModel: {},
      isShowDeleteButton: false,
      isShowKpiPicker: false,
      selectedData: [],
      currentKpi: 'kpi'
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    // isSpecialCheckRule () {
    //   const arr = ['mt_group_kpi_type_check_rule_1', 'mt_group_kpi_type_check_rule_2']
    //   return arr.indexOf(this.formModel.checkRule) > -1
    // },
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'kpi',
          slotName: 'kpi',
          labelWidth: '120px',
          col: { xs: 12, sm: 12, md: 12 },
          label: this.$t('config_group.kpi')
        },
        // {
        //   prop: 'compareKpi',
        //   slotName: 'compareKpi',
        //   labelWidth: '120px',
        //   hidden: this.tabIndex !== 3,
        //   col: { xs: 12, sm: 12, md: 12 },
        //   label: this.$t('config_group.compareKpi'),
        //   rules: { trigger: 'change', message: this.$t('validate.notBlank'), required: !this.isSpecialCheckRule }
        // },
        {
          prop: 'changeRateRule',
          type: 'NumberInput',
          labelWidth: '120px',
          hidden: this.tabIndex !== 1,
          col: { xs: 12, sm: 12, md: 12 },
          label: this.$t('config_group.fluctuationRange'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'thresholdRange',
          type: 'NumberInput',
          labelWidth: '120px',
          hidden: this.tabIndex !== 2,
          col: { xs: 12, sm: 12, md: 12 },
          label: this.$t('config_group.thresholdRange'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'thresholdAbsoluteValue',
          type: 'NumberInput',
          labelWidth: '120px',
          hidden: this.tabIndex !== 1,
          label: this.$t('config_kpi.fluctuationAbsoluteValue'),
          col: { xs: 12, sm: 12, md: 12 },
          component: { clearable: true, min: 0 }
          // rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'manualUpperLimit',
          type: 'NumberInput',
          labelWidth: '120px',
          hidden: this.tabIndex !== 2,
          col: { xs: 12, sm: 12, md: 12 },
          label: this.$t('config_group.manualUpperLimit'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: this.formModel.manualLowerLimit }
        },
        {
          prop: 'manualLowerLimit',
          type: 'NumberInput',
          labelWidth: '120px',
          hidden: this.tabIndex !== 2,
          col: { xs: 12, sm: 12, md: 12 },
          label: this.$t('config_group.manualLowerLimit'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: this.formModel.manualUpperLimit }
        }
        // {
        //   prop: 'checkRule',
        //   type: 'Select',
        //   labelWidth: '120px',
        //   hidden: this.tabIndex !== 3,
        //   col: { xs: 12, sm: 12, md: 12 },
        //   label: this.$t('config_group.checkRule'),
        //   component: {
        //     optionList: this.$getDictList('mt_group_kpi_type_check_rule'),
        //     valueKey: 'id',
        //     required: true
        //   },
        //   event: {
        //     change: val => {
        //       const arr = ['mt_group_kpi_type_check_rule_1', 'mt_group_kpi_type_check_rule_2']
        //       if (arr.indexOf(val) > -1) {
        //         delete this.formModel.compareKpi
        //       }
        //     }
        //   }
        // }
      ]
    },
    kpiSearchFormField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          labelWidth: '60px',
          col: { xs: 6, sm: 6, md: 6 },
          label: this.$t('config_kpi.name'),
          component: { clearable: true }
        },
        {
          prop: 'code',
          type: 'Input',
          labelWidth: '60px',
          col: { xs: 6, sm: 6, md: 6 },
          label: this.$t('config_kpi.code'),
          component: { clearable: true }
        },
        {
          prop: 'businessArea',
          type: 'Select',
          labelWidth: '60px',
          col: { xs: 6, sm: 6, md: 6 },
          label: this.$t('config_kpi.businessArea'),
          component: {
            optionList: this.$getDictList('mt_kpi_business_area'),
            valueKey: 'id',
            required: true,
            clearable: true
          }
        }
      ]
    },
    kpiTableColumn () {
      return [
        { prop: 'name', label: this.$t('config_kpi.name'), minWidth: 180 },
        { prop: 'code', label: this.$t('config_kpi.code'), minWidth: 120 },
        { prop: 'sort', label: this.$t('config_kpi.sort'), minWidth: 120 },
        { prop: 'fluctuateChangeRateRule', label: this.$t('config_kpi.fluctuationRange'), minWidth: 120 },
        { prop: 'thresholdChangeRateRule', label: this.$t('config_kpi.thresholdRange'), minWidth: 120 },
        { prop: 'thresholdAbsoluteValue', label: this.$t('config_kpi.fluctuationAbsoluteValue'), minWidth: 120 },
        { prop: 'isJoin', label: this.$t('config_kpi.isJoin'), minWidth: 120, slotName: 'yes_no' },
        { prop: 'isSpecial', label: this.$t('config_kpi.isSpecial'), minWidth: 120, slotName: 'yes_no' },
        { prop: 'businessArea', label: this.$t('config_kpi.businessArea'), minWidth: 160, slotName: 'mt_kpi_business_area' },
        {
          prop: 'calculationFormula',
          label: this.$t('config_kpi.calculationFormula'),
          formatter: (row, column, cellValue, index) => {
            return row.calculationFormula && (row.calculationFormula.length > 40) ? row.calculationFormula.slice(0, 39) + '...' : row.calculationFormula
          },
          minWidth: 500
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    getTitleByIndex (tabIndex) {
      const arr = ['fluctuationCheck', 'thresholdCheck', 'logicalCheck']
      return this.$t(`config_group.${arr[tabIndex - 1]}`)
    },
    isShowKpiSelector (name) {
      this.currentKpi = name
      this.isShowKpiPicker = true
    },
    confirmHandler (data) {
      this.formModel.changeRateRule = data[0].fluctuateChangeRateRule
      this.formModel.thresholdRange = data[0].thresholdChangeRateRule
      this.formModel.thresholdAbsoluteValue = data[0].thresholdAbsoluteValue
      this.$set(this.formModel, this.currentKpi, data[0])
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)
          this.$axios
            .post(this.tabIndex === 1 ? BASEURL.save1 : BASEURL.save2, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
          this.currentShowFlag = false
        }
      })
    },
    handleDialogOpen () {},
    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
