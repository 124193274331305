<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="page__wrapper">
    <simple-table
      :table-column="tableColumn"
      :table-data="tableData"
      :table-page-info="tablePageInfo"
      :table-data-count="tableDataTotalCount"
      @page-info-change="handlePageInfoChange"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'config_group:batchFluctuation'"
            type="primary"
            @click="handleBatchFluctuation"
          >
            {{ $t('config_group.batchFluctuation') }}
          </el-button>
          <el-button
            v-permission="'config_group:batchThreshold'"
            type="primary"
            @click="handleBatchThreshold"
          >
            {{ $t('config_group.batchThreshold') }}
          </el-button>
          <el-button
            v-permission="'config_group:export'"
            type="primary"
            @click="handleExportClick"
          >
            {{ $t('operation.export') }}
          </el-button>
          <el-button
            v-permission="'config_group:add'"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="type"
        slot-scope="scope"
      >
        {{ $getDictLabel({ type: 'mt_kpi_type', value: scope.row.type }) }}
      </template>
      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-edit"
          :title="$t('operation.edit')"
          @click="handleEditClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-document-copy"
          :title="$t('operation.copy')"
          @click="handleCopyClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-upload2"
          :title="$t('operation.import')"
          @click="handleDataImpotClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-download"
          :title="$t('operation.export')"
          @click="handleDataExportClick(scope.row)"
        />
        <el-button
          circle
          icon="el-icon-delete"
          :title="$t('operation.delete')"
          @click="deleteRecord(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
      :copy-flag="isCopyFlag"
    />
    <batchKpi
      :show-flag.sync="isShowBatchKpi"
      :tab-index="batchKpiType"
    />

    <!-- 行项目数据导入 -->
    <!-- <simple-upload
      ref="uploader"
      url="/jlr/mt/groupItem/fileUpload"
      :params="{headId: rowData.id}"
      file-type="excel"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    /> -->
    <simple-upload
      ref="uploader"
      url="/download/mt/groupItem/fileUpload"
      :params="{ headId: rowData.id }"
      file-type="excel"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />
  </div>
</template>
<script>
import pageMixins from '@/components/base/simple-table/mixin'
import detailDialog from './detail'
import batchKpi from './batchKpi'
const BASEURL = {
  list: '/jlr/mt/group/list',
  delete: '/jlr/mt/group/delete',
  export: '/jlr/mt/group/export',
  itemExport: '/jlr/mt/groupItem/export'
}
export default {
  name: 'ConfigGroup',
  components: { detailDialog, batchKpi },
  mixins: [pageMixins],
  data () {
    return {
      rowData: {},
      tableData: [],
      searchModel: {},
      isViewFlag: false,
      isCopyFlag: false,
      isShowBatchKpi: false,
      isShowDetailDialog: false,
      isShowUploader: false,
      batchKpiType: 1
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          labelWidth: '50px',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('config_group.name'),
          component: { clearable: true }
        },
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          style: { textAlign: 'right' },
          col: { xs: 20, sm: 20, md: 20 }
        }
      ]
    },
    tableColumn () {
      return [
        { prop: 'name', label: this.$t('config_group.name'), minWidth: 200 },
        { prop: 'sort', label: this.$t('config_group.sort'), minWidth: 100 },
        { prop: 'createBy.name', label: this.$t('config_group.createBy'), minWidth: 140 },
        { prop: 'createDate', label: this.$t('config_group.createDate'), minWidth: 140 },
        { prop: 'updateBy.name', label: this.$t('config_group.updateBy'), minWidth: 140 },
        { prop: 'updateDate', label: this.$t('config_group.updateDate'), minWidth: 140 },
        { label: this.$t('field.operation'), slotName: 'operation', fixed: 'right', width: 180 }
      ]
    }
  },
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const searchInfo = this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      let pageInfo = { page: this.tablePageInfo }
      pageInfo.page.orderBy = 'sort'
      const params = Object.assign({}, pageInfo, searchInfo)
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = { pageNo: respData.pageNo, pageSize: respData.pageSize }
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.rowData = {}
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      this.isShowDetailDialog = true
    },
    handleEditClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      this.isCopyFlag = false
      this.isShowDetailDialog = true
    },
    handleCopyClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      this.isCopyFlag = true
      this.isShowDetailDialog = true
    },
    deleteRecord (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleExportClick (row) {
      const searchInfo = this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      let pageInfo = { page: this.tablePageInfo }
      pageInfo.page.orderBy = 'sort'
      const params = Object.assign({}, pageInfo, searchInfo)
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.export, params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
      this.isShowTemplateDialog = false
    },
    handleDataExportClick (row) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.itemExport, { head: { id: row.id, name: row.name } })
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
      this.isShowTemplateDialog = false
    },
    handleDataImpotClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isShowUploader = true
    },
    handleUploadSuccess () {
      this.isShowUploader = false
      this.handleDataSearch()
    },
    handleBatchFluctuation () {
      this.batchKpiType = 1
      this.isShowBatchKpi = true
    },
    handleBatchThreshold () {
      this.batchKpiType = 2
      this.isShowBatchKpi = true
    }
  }
}
</script>
